import { render, staticRenderFns } from "./slider-index-slide2.vue?vue&type=template&id=70a7d2d5&"
import script from "./slider-index-slide2.vue?vue&type=script&lang=js&"
export * from "./slider-index-slide2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PictureComponent: require('/home/d/didisher2/pioner-cars.ru/www/components/picture/picture-component.vue').default})
