
import AOS from 'aos'
import 'aos/dist/aos.css'
import { mapGetters } from "vuex";



export default {
	data() {
		return {
			sliderIndexSwiper: null,
			paginationInit: false
		}
	},
	mounted() {
		// console.log(this.settings)
		// AOS.init({
		// 	once: true
		// })
		// setTimeout(() => {
		this.sliderIndexSwiper = new swiper.default('.slider-index .swiper.swiper--index.swiper--maiSlider', {
			modules: [swiper.Navigation, swiper.Pagination, swiper.Autoplay],
			loop: true,
			// loopedSlides: 4,
			// autoplayDisableOnInteraction: false,
			// autoplay: true,
			slidesPerView: 1,
			// autoHeight: true,
			height: 800,
			// watchSlidesProgress: true,
			// scrollbar: false,
			// speed: 1800,
			autoplay: false,
			enabled: true,
			// on: {
			// sliderMove: this.sliderMove,
			// slideChangeTransitionStart: this.slideChangeTransitionStart
			// },
			pagination: {
				el: '.swiper-pagination.swiper-pagination--index',
				clickable: 'true',
				type: 'bullets',
				// renderBullet: function (index, className) {
				// 	return '<span class="' + className + '">' + '<span class="swiper-pagination-bar"></span>' + '<span class="swiper-pagination-progress"></span>' + '</span>';
				// },
			},
			navigation: {
				nextEl: '.swiper-button-next--index',
				prevEl: '.swiper-button-prev--index',
			},
			on: {
				afterInit: (value) => {
					this.paginationInit = true
				}
			}
		})
		// }, 100)


	},
	methods: {
		sliderMove() {
			document.querySelectorAll('.swiper-slide-next .slider-index__media').forEach((val) => {
				// val.style.display = 'none'
				// val.classList.remove("aos-init", "aos-animate");
				// AOS.init({
				// 	once: true
				// })
				// val.style.display = 'block'
			})
			document.querySelectorAll('.swiper-slide-next .slider-index__circle').forEach((val) => {
				val.style.display = 'none'
				val.classList.remove("aos-init", "aos-animate");
				val.style.display = 'block'
				val.classList.add("aos-init", "aos-animate");
			})
			// AOS.init()
		},
		slideChangeTransitionStart() {
			// document.querySelectorAll('.swiper-slide-active .slider-index__media').forEach((val) => {
			// 	val.style.display = 'none'
			// 	val.classList.remove("aos-init");
			// 	val.style.display = 'block'
			// })
			// document.querySelectorAll('.swiper-slide-next .slider-index__circle').forEach((val) => {
			// 	val.style.display = 'none'
			// 	val.classList.remove("aos-init");
			// 	val.style.display = 'block'
			// })
			// AOS.refreshHard()
		}
	},
	computed: {
		...mapGetters({
			settings: 'settings/settings/settings'
		}),
		slides() {
			return [
				//{
				// 	id: 9,
				// 	heading: 'Новогодние скидки <br><span>только до 15.01.2024 г.</span>',
				// 	content: `Успейте купить автомобиль со скидкой <span>до 400 000 ₽</span>`,
				// 	link: '/credit',
				// 	button: 'ПОЛУЧИТЬ ПРЕДЛОЖЕНИЕ',
				// 	image: {
				// 		x1: require('~/assets/img/slider-main/back-9@x1.webp'),
				// 		x2: require('~/assets/img/slider-main/back-9@x2.webp')
				// 	}
				// },
				{
					id: 1,
					heading: 'Выгодный автокредит <span>от 4.9%</span>',
					content: `Экономия <span>${this.settings.sale_credit}</span> ₽ при покупке автомобиля в кредит в ПИОНЕРАВТО`,
					link: '/credit',
					button: 'ПОЛУЧИТЬ ПРЕДЛОЖЕНИЕ',
					image: {
						x1: require('~/assets/img/slider-main/slide-1@1x.webp'),
						x2: require('~/assets/img/slider-main/slide-1@2x.webp')
					}
				},
				{
					id: 2,
					// heading: `СберАвто и АвтоЛидер`,
					heading: `Выгодный TRADE IN <br><span>в Санкт-Петербурге</span>`,
					content: `Быстрый обмен старого автомобиля на новый с выгодой ${this.settings.sale_tradein} ₽`,
					link: '/exchange',
					button: 'ПОЛУЧИТЬ ПРЕДЛОЖЕНИЕ',
					image: {
						x1: require('~/assets/img/slider-main/slide-2@1x.webp'),
						x2: require('~/assets/img/slider-main/slide-2@2x.webp')
					}
				},
				{
					id: 3,
					heading: 'Обмен старого <br> автомобиля <span>на новый</span>',
					content: `С выгодой ${this.settings.sale_tradein} ₽! Автосалон предоставляет дополнительную техническую гарантию на все автомобили`,
					link: '/cars',
					button: 'УЗНАТЬ БОЛЬШЕ',
					image: {
						x1: require('~/assets/img/slider-main/slide-3@1x.webp'),
						x2: require('~/assets/img/slider-main/slide-3@2x.webp')
					}
				},
				{
					id: 4,
					heading: 'Каско <br><span>в подарок</span>',
					content: `при покупке любого авто`,
					link: '/cars',
					button: 'УЗНАТЬ БОЛЬШЕ',
					image: {
						x1: require('~/assets/img/slider-main/slide-4@1x.webp'),
						x2: require('~/assets/img/slider-main/slide-4@2x.webp')
					}
				},
				{
					id: 5,
					heading: 'Дарим <span>подарки!</span>',
					content: `Осаго и 2-ой комплект резины`,
					link: '/cars',
					button: 'УЗНАТЬ БОЛЬШЕ',
					image: {
						x1: require('~/assets/img/slider-main/slide-5@1x.webp'),
						x2: require('~/assets/img/slider-main/slide-5@2x.webp')
					}
				},
				// {
				// 	id: 4,
				// 	heading: 'МГНОВЕННЫЙ ВЫКУП',
				// 	content: `высокая оценка. выплата в день обращения`,
				// 	link: '/buyout',
				// 	button:'ОСТАВИТЬ ЗАЯВКУ',
				// 	image: {
				// 		x1: require('~/assets/img/slider-main/back-4@x1.webp'),
				// 		x2: require('~/assets/img/slider-main/back-4@x2.webp')
				// 	}
				// },

				// {
				// 	id: 6,
				// 	heading: 'TRADE-IN',
				// 	content: `выгода ${this.settings.sale_tradein} ₽`,
				// 	link: '/exchange',
				// 	button:'ОСТАВИТЬ ЗАЯВКУ',
				// 	image: {
				// 		x1: require('~/assets/img/slider-main/back-6@x1.webp'),
				// 		x2: require('~/assets/img/slider-main/back-6@x2.webp')
				// 	}
				// },
				// {
				// 	id: 7,
				// 	heading: `АВТОКРЕДИТ ОТ ${this.settings.credit_percent}`,
				// 	content: `первый взнос от 0 ₽`,
				// 	link: '/credit',
				// 	button:'ОНЛАЙН КАЛЬКУЛЯТОР',
				// 	image: {
				// 		x1: require('~/assets/img/slider-main/back-7@x1.webp'),
				// 		x2: require('~/assets/img/slider-main/back-7@x2.webp')
				// 	}
				// },
				// {
				// 	id: 8,
				// 	heading: `РАССРОЧКА ${this.settings.installment_percent}`,
				// 	content: `покупка без переплат от Совкомбанк`,
				// 	link: '/installments',
				// 	button:'ОСТАВИТЬ ЗАЯВКУ',
				// 	image: {
				// 		x1: require('~/assets/img/slider-main/back-8@x1.webp'),
				// 		x2: require('~/assets/img/slider-main/back-8@x2.webp')
				// 	}
				// },

			]
		}
	}
}
